import React from 'react'

const BackButton = () => {
  return (
    <div sx={{postion: "absolute", left: "20px", top: "20px", backgroundColor:"red", width: "50px", height: "50px", borderRadius: "50%"}}>
12345

    </div>
  )
}

export default BackButton